import './loadingicon.scss';

import { Puff } from 'react-loading-icons';

import StyleVariables from '../styles/base/variables';

interface LoadingIconProps {
    className?: 'loading-icon-wrapper' | 'loading-icon-wrapper-large-margin-top' | 'loading-icon-wrapper-no-margin-top';
    hexColour?: string;
}

const LoadingIcon = (props: LoadingIconProps) => {
    const { className, hexColour } = props;
    
    return (
        <div className={className ? className : 'loading-icon-wrapper'}>
            <Puff stroke={hexColour ? hexColour : StyleVariables.orangePrimary}/>
        </div>
    )
}

export default LoadingIcon;