import './paymentpageprofile.scss';

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import CurrencyInput from 'react-currency-input-field';
import { TfiPencil } from 'react-icons/tfi';

import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';

import GetPaymentIntentRequest from '../../classes/GetPaymentIntentRequest';
import ProfileDisplayData from '../../classes/ProfileDisplayData';
import UserProfileData from '../../classes/UserProfileData';
import ErrorMessage from '../../components/ErrorMessage';
import OmniaModal from '../../components/OmniaModal';
import Constants from '../../configuration/constants';
import HelperMethods from '../../helpers/HelperMethods';
import EditProfileModal, {
  ProfileAttribute,
} from '../vieweditprofile/editprofilemodal/EditProfileModal';
import PaymentArea from './PaymentArea';

/** 
 * Stripe Express Checkout Element docs: https://stripe.com/docs/elements/express-checkout-element/accept-a-payment?client=react
 * Stripe keys documentation: https://stripe.com/docs/keys?locale=en-GB
 */

const stripePromise: Promise<Stripe|null> = loadStripe('pk_test_51ObV3iIVpqga7aO7xexL2HHbCENhJeXpbn2lAR9QXk9mQguLse4Rvr1T5EMq7kbtU6LgQdsKvHLCx9hRH6fsNSvC00sQCkfWWs');

interface PaymentPageProfileProps {
  profileDisplayData: ProfileDisplayData;
  userProfileData?: UserProfileData;
  isPreview: boolean;
}

const PaymentPageProfile = (props: PaymentPageProfileProps): ReactElement => {
  const { profileDisplayData, userProfileData, isPreview } = props;

  const [payAmount, setPayAmount] = useState<number>(0);
  const [customValue, setCustomValue] = useState<string|undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number>(-2); // selectedIndex === -2 indicates no selection
  const [coverPaymentFees, setCoverPaymentFees] = useState<boolean>(true);
  const [feeModalVisible, setFeeModalVisible] = useState<boolean>(false);
  const [transactionAmount, setTransactionAmount] = useState<number>(0);
  const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [profileAttribute, setProfileAttribute] = useState<ProfileAttribute>('base64ProfileImage');

    useEffect(() => {
      changePayAmount(payAmount, selectedIndex);
    }, [payAmount, selectedIndex, coverPaymentFees]);

    const handleCustomValueChange = (value: string|undefined) => {
      if (value !== undefined && parseFloat(value) > Constants.maximumTransactionAmount) {
        return
      } else if (value !== undefined) {
        setCustomValue(value);
        changePayAmount(parseFloat(value), -1);
      } else if (customValue !== undefined) { // previous customValue !== undefined
        changePayAmount(0, -2);
      }
    }

    const handleCustomValueOnBlur = () => {
      if (customValue === undefined) {
        setSelectedIndex(-2);
        setCustomValue(undefined);
      } else if (parseFloat(customValue) < Constants.minimumTransactionAmount) {
        setCustomValue(Constants.minimumTransactionAmount.toFixed(2));
        changePayAmount(Constants.minimumTransactionAmount, -1);
      }
    }

    const changePayAmount = (value: number, index: number) => {
      setPayAmount(value);
      setSelectedIndex(index);
      const fees: number = HelperMethods.getTotalFee(value);
  
      if (index !== -1) { // selectedIndex === -1 indicates custom amount is selected
        setCustomValue(undefined);
      }
  
      if (value === 0) {
        setTransactionAmount(0);
      } else {
        const transactionValue: number = value + (coverPaymentFees ? fees : 0);
        setTransactionAmount(transactionValue);
      }
    }

    const toggleFeeModal = () => {
      setFeeModalVisible(!feeModalVisible);
    }

    const toggleEditModal = (isModalVisible: boolean, setIsModalVisibleState: Dispatch<SetStateAction<boolean>>, profileAttribute: ProfileAttribute): void => {
      setProfileAttribute(profileAttribute);
      setIsModalVisibleState(!isModalVisible);
    }

    /**
     * Documentation for StripeElementsOptions: https://stripe.com/docs/js/elements_object/create_without_intent
     */
    const stripeElementsOptions: StripeElementsOptions = {
      mode: 'payment',
      amount: (transactionAmount < Constants.minimumTransactionAmount) ? Constants.minimumTransactionAmount*100 : Math.round(transactionAmount*100),
      currency: (profileDisplayData && profileDisplayData.currency) ? profileDisplayData.currency : 'gbp' 
    };

    return (
      <div className='payment-page-centre-content'>
          { profileDisplayData.base64ProfileImage ?
            <div className='payment-page-profile-image-wrapper'>
              { isPreview &&
                <div className='edit-profile-image-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'base64ProfileImage')}>
                  <TfiPencil className='edit-profile-pencil-icon'/> 
                </div>
              }
              <img className='payment-page-profile-image' src={profileDisplayData.base64ProfileImage} alt='img'/>
            </div>
            : <div className='margin-top'/>
          }
          { profileDisplayData.displayName &&
            <div className='profile-name-wrapper'>
              { isPreview &&
                <div className='edit-profile-name-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'displayName')}>
                  <TfiPencil className='edit-profile-pencil-icon'/> 
                </div>
              }
              <div className='payment-page-profile-name'>{profileDisplayData.displayName}</div>
            </div>
          }
          <div className='pay-selection-box'>
            { isPreview &&
              <div className='edit-suggested-pay-values-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'suggestedPayValues')}>
                <TfiPencil className='edit-profile-pencil-icon'/> 
              </div>
            }
            { profileDisplayData.suggestedPayValues.length > 0 &&
              <>
                <div className='select-an-amount-text'>Select an amount:</div>
                <div className='pay-selection-buttons'>
                { profileDisplayData.suggestedPayValues.map((value, index) => (
                    <div 
                    key={index}
                    onClick={() => changePayAmount(value, index)}
                    className={`pay-selection-button ${selectedIndex === index ? 'selected' : ''}`}
                    >
                    £ {value.toFixed(2)}
                    </div>
                ))}
                </div>
              </>
            }
            <div className='custom-value-section'>
              <div className='custom-value-text-section'>
              <div className='enter-custom-amount-text'>{profileDisplayData.suggestedPayValues.length > 0 ? 'or enter a custom amount:' : 'Enter an amount:'}</div>
              <div className='minimum-amount-text'>(min £{Constants.minimumTransactionAmount.toFixed(2)})</div>
              </div>
              <div className='custom-value-input-section'>
              <div className='custom-value-currency'>£</div>
              <CurrencyInput
                className={`custom-amount-input-box ${selectedIndex === -1 ? 'selected' : ''}`}
                id='custom-value-input'
                name='custom-value-input'
                value={customValue}
                decimalsLimit={2}
                decimalScale={2}
                onValueChange={(value) => handleCustomValueChange(value)}
                onBlur={handleCustomValueOnBlur}
              />
            </div>
          </div>
          </div>
          { !isPreview && 
            <>
              <div className='transaction-total-section'>
                <div className='total-text'>Total</div>
                <div className='transaction-amount'>£{transactionAmount.toFixed(2)}</div>
              </div>
              <div className='cover-fees-section'>
                <div className='cover-fees-selection'>
                  <input
                    className='cover-fees-radio-button'
                    type='checkbox'
                    checked={coverPaymentFees}
                    onChange={() => setCoverPaymentFees(!coverPaymentFees)}
                  />
                  <div className='cover-fees-text'>Cover processing fee{profileDisplayData.displayName ? ` for ${profileDisplayData.displayName}` : ''}</div>
                </div>
                <div className={`fee-breakdown ${isPreview ? 'disabled' : ''}`} onClick={toggleFeeModal}>what is this fee?</div>
                { feeModalVisible &&
                  <OmniaModal
                    modalTitle='Fee Breakdown'
                    modalDescription='As a small startup dedicated to supporting individuals, we keep fees minimal. Transparent breakdown: a modest company fee ensures sustainability, and a nominal transaction fee ensures your tips go directly to the deserving recipients who make a difference.'
                    modalBody={
                      <div>
                        <div className='fee-breakdown-section'>
                          <div className='fee-section'>
                            <div className='fee-title'>Processing Fee: {HelperMethods.getFeeString(Constants.paymentProcessingFeeMultiplier, Constants.paymentProcessingFeeConstant)}</div>
                            <div className='fee-body'>We are always working hard with our payment processors to make this as low as possible.</div>
                          </div>
                          <div className='fee-section'>
                            <div className='fee-title'>Omnia Fee: {HelperMethods.getFeeString(Constants.omniaFeeMultiplier, Constants.omniaFeeConstant)}</div>
                            <div className='fee-body'>This fee goes towards helping us grow so we can help as many people as possible.</div>
                          </div>
                        </div>
                      </div>
                    }
                    onClose={toggleFeeModal}
                    isVisible={feeModalVisible}
                  />
                }
              </div>
            </>
          }
          { !isPreview &&
            <div className={`payment-section ${feeModalVisible ? 'hide-element' : ''}`}>
              { stripePromise &&
                <Elements stripe={stripePromise} options={stripeElementsOptions}>
                  <PaymentArea 
                    getPaymentIntentRequest={new GetPaymentIntentRequest({
                      displayName: profileDisplayData.displayName,
                      transactionAmount: Math.max(Constants.minimumTransactionAmount, transactionAmount),
                      currency: profileDisplayData.currency,
                      username: profileDisplayData.username,
                      stripeConnectedAccountId: profileDisplayData.stripeConnectedAccountId
                    })}
                    onErrorMessage={setCustomerErrorMessage}
                  />
                </Elements>
              }
            </div>
          }
          { customerErrorMessage !== '' &&
            <ErrorMessage errorMessage={customerErrorMessage}/>
          }
          { isPreview && userProfileData &&
            <EditProfileModal
              userProfileData={userProfileData}
              profileAttribute={profileAttribute}
              onClose={() => toggleEditModal(editModalVisible, setEditModalVisible, profileAttribute)}
              isVisible={editModalVisible}
            />
          }
      </div>
    );
}

export default PaymentPageProfile;
