import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import ApiResponse from '../../classes/ApiResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import UserProfileData from '../../classes/UserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import Constants from '../../configuration/constants';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import { getUserProfileDataAsync } from '../../redux/userProfileDataSlice';
import LandingPage from './LandingPage';
import SignedInPage from './SignedInPage';

const HomePage = (): ReactElement => {
    const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);

    const reduxUserProfilData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
        return await ApiResponse.getApiResponse(
          Constants.getAccountStateFromTokenEndpoint,
          null,
          GetAccountStateFromTokenResponse,
          null,
          null,
          true
        );
      };
        
      getAccountStateFromToken().then(response => {
        if (!response || (response instanceof AxiosError && response.request.status === 401)) {
          setIsSignedIn(false);
        } else {
          setIsSignedIn(true);
          if (!reduxUserProfilData) {
            dispatch(getUserProfileDataAsync());
          }
        }
      });
    }, [isSignedIn]);

    if (isSignedIn === undefined) {
      return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    } else if (isSignedIn === false) {
      return <LandingPage/>;
    } else {
      return <SignedInPage/>;
    }
}

export default HomePage;
